@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');


:root {
  --primary-color: #E58A27;
  --secondary-color : #3C3C3B;
  --black: black;
  --white: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}


/* Basics */

.top-5{
  margin-top: 5px !important;
}

.top-15{
  margin-top: 15px !important;
}

.top-25{
  margin-top: 25px !important;
}

.top-50{
  margin-top: 50px !important;
}

.left-5{
  margin-left: 5px !important;
}

.left-15{
  margin-left: 15px !important;
}

.left-25{
  margin-left: 25px !important;
}

.left-50{
  margin-left: 50px !important;
}

.right-5{
  margin-right: 5px !important;
}

.right-15{
  margin-right: 15px !important;
}

.right-25{
  margin-right: 25px !important;
}

.right-50{
  margin-right: 50px !important;
}


.next{
display: flex !important;
align-items: center;
justify-content: space-between;
}

.color-primary{
  color: var(--primary-color);
}

.color-secondary{
  color: var(--secondary-color) !important;
}

.italic{
  font-style: italic;
}

.bold{
  font-weight: bold;
}

.app-container{
  
}

.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}

a{
  text-decoration: none !important;
  color: var(--black) !important;
}

/* Navbar */

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  padding : 25px 50px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid var(--secondary-color);
  z-index: 1000;
}

.nav-logo{
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.nav-logo a{
  display: flex;
  align-items: center;
}

.nav-logo a img{
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.nav-right{
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}

/* src/styles/navbar.css */

.nav-link {
  position: relative;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-active {
  color: var(--primary-color) !important;
  font-weight: bold;
}


/* Footer */
footer{
  display: flex;
  justify-content: space-between;
  padding: 50px 100px 25px;
  background-color: var(--black);
  color: var(--white);
  border-bottom: 5px solid var(--primary-color);
}

.footer-part-text a {
  color: var(--white) !important;
}

.footer-part{
  width: 30%;
}

.footer-part-title{
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-part-text{
  font-size: 0.9rem;
  color: var(--white);
}

/* HOMEPAGE */

.container-home {
  margin-top: 25px;
  padding: 50px 100px;
}

.landing {
  margin-top: 100px;
  height: 90vh;
  display: flex;
  justify-content: space-between;
}

.landing-info {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.small-title {
  font-size: 1rem;
  color: var(--primary-color);
}

.landing-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: var(--white);
}

.landing-subtitle {
  margin-top: 10px;
  font-size: 1.2rem;
  color: var(--white);
}
.landing-carousel {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100vh;
  overflow: hidden;
  border-bottom: 3px solid var(--primary-color);
  overflow-x: hidden;
}

.carousel-img {
  border-radius: 50px 0 0 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
  filter: brightness(50%);
  position: absolute;
  top: 0;
  left: 0;
}

.slide-in-right {
  transform: translateX(100%);
  animation: slide-in-right 0.5s forwards;
}



@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/*LANG*/
.language-switcher {
  position: relative;
  display: inline-block;
}

.language-switcher-btn {
  background-color: #fff; /* Change this to your desired button color */
  border: 1px solid #ddd; /* Border around the button */
  padding: 8px 12px;

  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.language-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Background color of the dropdown */
  border: 1px solid #ddd; /* Border around the dropdown */
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
  z-index: 1000;
}

.language-option {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.language-option:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
}

.flag {
  display: flex;
  margin-right: 8px;
}

.flag-name{
  font-size: 0.9rem;
  margin-left: 5px;
}


.landing-socials {
  display: flex;
  margin-top: 50px;
}

.landing-socials svg {
  transition: color 0.3s ease-in-out;
}

.landing-socials svg:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.social-icon {
  font-size: 1.5rem;
  margin-right: 5px;
  color: var(--white);
}

.button-group {
  margin-top: 15px;
  display: flex;
}

.button-full {
  transition: background-color 0.3s ease-in-out;
  padding: 10px 25px;
  background-color: var(--primary-color);
  color: var(--white) !important;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  width: max-content;
  font-weight: bold;
}

.button-full:hover {
  background-color: var(--white);
  color: var(--secondary-color) !important;
}

.button-outline {
  transition: background-color 0.3s ease-in-out;
  padding: 10px 25px;
  background-color: transparent;
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
  border-radius: 0px;
  cursor: pointer;
  width: max-content;
  font-weight: bold;
}

.language-switcher-btn-text{
  margin-left: 5px;
}

.button-outline:hover {
  background-color: var(--primary-color);
  color: var(--white) !important;
}

.arrow-down{
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translateX(-50%);
  font-size: 2rem;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translate(-50%, 0);
  }
  40% {
    transform: translate(-50%, -10px);
  }
  60% {
    transform: translate(-50%, -5px);
  }
}

.arrow-down svg{
  color: var(--white);
  transition: color 0.3s ease-in-out;
}

/* EXCLUSIVE PARTNERS */
.page-title{
  font-size: 2rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.page-content{
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.grid-partners{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  margin-top: 50px;
}


.partner{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px;
  background-color: var(--white);
  border : 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  
  transition: 0.5s;
  transition: transform 0.5s, box-shadow 0.5s; /* Add transition for smooth hover effect */

}


.partner:hover {
  transform: translateY(-5px); /* Slightly lift the card */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}


.partner-logo{
  width: 100%;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
  padding : 10px;
  overflow: hidden;
  transition: transform 0.3s; /* Add transition for logo hover effect */


}

.partner-logo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.partner-content{
  margin-top: 25px;
  position: relative;
  padding-bottom: 25px;
  height: 100%;
}

.partner-name{
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary-color);
}

.partner-desc{
  margin-top: 10px;
  font-size: 0.9rem;
  color: var(--secondary-color);
}

.partner-link{
  position: absolute;
  bottom: 0;
  margin-top: 100px !important;
  color: var(--primary-color) !important;
  border-bottom: 1px solid var(--primary-color);
  transition: color 0.3s, border-bottom-color 0.3s; /* Add transition for link hover effect */
}
.partner:hover .partner-logo {
  transform: scale(1.02); /* Slightly enlarge the logo on hover */
}

.partner:hover .partner-link {
  color: var(--secondary-color) !important; /* Change link color on hover */
  border-bottom-color: var(--secondary-color); /* Change border color on hover */
}

/* ABOUT US */
.about {
  margin-top: 100px;
}

.about-upper{
  
  padding: 50px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.about-information{
  width: 55%;

}

.page-desc{
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin-top: 25px;
  margin-bottom: 50px;

}

.about-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  padding-top: 50px;
  border-top: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  padding: 50px 100px;
}

.about-card {
  background-color: var(--white);
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  padding: 25px;
  transition: transform 0.5s, box-shadow 0.5s;
}

.about-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.about-icon{
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 15px;

}

.about-image{
  width: 30%;
  height: 250px;
  overflow: hidden;
  border-bottom: 3px solid var(--primary-color);
}
.about-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;  
  margin-bottom: 15px;

}

.about-card h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.about-card p, .about-card ul {
  font-size: 1rem;
  color: var(--secondary-color);
  line-height: 1.5;
}

.about-card ul {
  list-style-type: disc;
  padding-left: 20px;
}

.about-card ul li {
  margin-bottom: 10px;
}


/* Projects */

.project-card{
  width: 100%;
  
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border : 1px solid rgb(237, 237, 237);
  
  transition: 0.5s;
  transition: transform 0.5s, box-shadow 0.5s; /* Add transition for smooth hover effect */
}

.project-card:hover {
  transform: translateY(-5px); /* Slightly lift the card */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.project-content{
  padding: 25px;

}

.project-card img{
  border-radius: 5px 5px 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
}

.project-card h2{
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.project-card p{
  color: var(--secondary-color);
  line-height: 1.5;
}

.project-card ul{
  list-style-type: disc;
  padding-left: 20px;
}

.project-card ul li{
  margin-bottom: 10px;
}


/* CONTACT PAGE */
.contact {
  margin-top: 100px;
}

.contact-upper {
  display: flex;
  padding: 50px 100px;
  margin-bottom: 50px;
  width: 55%;
}


.contact-content {
  background-color: var(--primary-color);
  padding: 50px 100px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  justify-content: center;
}

.contact-cards{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

}

.contact-card-sub div{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.contact-card-sub div strong{
  margin-bottom: 5px;
}

.contact-card {
  background-color: var(--white);
  border: 1px solid rgb(237, 237, 237);
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s, box-shadow 0.5s;
  display: flex;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.contact-card h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 15px;
}


.contact-card a {
  color: var(--primary-color);
  text-decoration: none;
}

.contact-card a:hover {
  text-decoration: underline;
}

.contact-map iframe {
  width: 100%;
  height: 450px;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.contact-card-part{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.mid{
  text-align: left;
}

.extra-image-div{
  display: flex;
  justify-content: center;
  align-items: center;
}
.extra-image{
  width: 70% !important;
  height: auto !important;
  object-fit: cover;
  border-radius: 5px;

}

.ham-menu{
  display: none;
}

.nav-popup-title{
  font-size: 1.2rem;
  font-weight: bold;
  
}

.nav-popup-logo{
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.nav-popup-up{
  display: flex;
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 10px;
  align-items: center;
  margin-bottom: 25px;
}

.nav-popup-upper{
  display: flex;
  flex-direction: column;
  padding : 25px;
}

.bot{
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  
}


/* Mobile */
@media (max-width: 768px) {
  
  nav {
    padding: 15px 25px;
  }

  .nav-right{
    display: none;
  }

  


.nav-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  color: #333;
  transition: right 0.3s ease;
  border-left: 1px solid #333;
  z-index: 1 !important;
}


.nav-popup.open {
  right: 0;
}

.nav-popup  {
  list-style: none;
}

.nav-menu-icon {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2 !important;
}

.nav-popup .nav-link {
  margin: 1rem 0;
  margin-left: 0px !important;
}

.nav-popup .nav-link  {
  text-decoration: none;
  color: #333;
}

  .container-home{
    padding: 25px;
  }

  .landing{
    height: 85vh;
  }

  .landing-info{
    width: 100%;
  }
  .landing-title{
    font-size: 2.5rem;
  }
  .landing-subtitle{
    font-size: 1rem;
  }
  .landing-socials{
    margin-top: 25px;
  }
  .button-group{
    position: absolute;
    bottom: 85px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
  }
  .button-full, .button-outline{
    width: 90vw;
    margin: 5px 0 !important;
    text-align: center;
    padding : 15px 0;
  }

  .mid{
    text-align: center;
  }
  .landing-carousel{
    height: 100vh;
  }

  .arrow-down{
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%);
    font-size: 2rem;
  }

  .partners{
    padding: 5px;
    margin-top: 25px;
  }

  .grid-partners{
    grid-template-columns: 1fr;
  }
  .page-title{
    text-align: center;
    font-size: 1.5rem;
  }
  .page-content{
    text-align: center;
  }

  footer{
    padding: 50px 25px 25px 25px;
    flex-direction: column;
  }

  .footer-part{
    width: 100%;
    margin-bottom: 50px;
  }

  .about-upper{
    padding: 25px;
    flex-direction: column;
  }

  .about-upper .page-title{
    font-size: 1.5rem;
    text-align: left;
  }

  .about-information{
    width: 100%;
  }

  .page-desc{
    font-size: 1rem;
  }

  .about-image{
    width: 100%;
    height: 200px;
  }

  .about-content{
    padding: 25px;
    grid-template-columns: 1fr;
  }

  .about-card{
    padding: 15px;
  }

  .contact-upper{
    padding: 25px;
    width: 100%;
  }

  .contact-upper .page-title{
    font-size: 1.5rem;
    text-align: left;
  }

  .contact-content{
    padding: 25px;
  }
  .contact-cards{
    grid-template-columns: 1fr;
  }

  .ham-menu{
    display: block;
  }
  
  .language-switcher{
    margin-right: 8px;
    margin-bottom: 5px;
  }


}
